define("discourse/plugins/discourse-char-coordinator/discourse/templates/components/render-children-nodes-only", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  {{#if child_map }}
  
  <ul>
    {{#each child_map as |item|}}
    <li>
      <span onclick={{ action 'loadTreeNode' item }}>[{{ item.id }}] {{ item.name }}</span>
  
      {{#if item.child_map }}
      {{ render-children-nodes-only child_map=item.child_map loadTreeNode=(action 'loadTreeNode') }}
      {{/if}}
  
    </li>
  
    {{/each}}
  </ul>
  
  {{/if}}
  
  */
  {
    "id": "EKPGei9f",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"child_map\"]],[[[1,\"\\n\"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"child_map\"]]],null]],null],null,[[[1,\"  \"],[10,\"li\"],[12],[1,\"\\n    \"],[10,1],[15,\"onclick\",[28,[37,6],[[30,0],\"loadTreeNode\",[30,1]],null]],[12],[1,\"[\"],[1,[30,1,[\"id\"]]],[1,\"] \"],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\\n\"],[41,[30,1,[\"child_map\"]],[[[1,\"    \"],[1,[28,[35,7],null,[[\"child_map\",\"loadTreeNode\"],[[30,1,[\"child_map\"]],[28,[37,6],[[30,0],\"loadTreeNode\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[1]],null],[13],[1,\"\\n\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `child_map` property path was used in the `discourse/plugins/discourse-char-coordinator/discourse/templates/components/render-children-nodes-only.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.child_map}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `child_map` property path was used in the `discourse/plugins/discourse-char-coordinator/discourse/templates/components/render-children-nodes-only.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.child_map}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"item\"],false,[\"if\",\"ul\",\"each\",\"-track-array\",\"li\",\"span\",\"action\",\"render-children-nodes-only\"]]",
    "moduleName": "discourse/plugins/discourse-char-coordinator/discourse/templates/components/render-children-nodes-only.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});